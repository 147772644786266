<template>
  <div justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="85rem"
      content-class="custom-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          rounded
          @click="fetchModalCreate"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t("message.create") }}
        </v-btn>
      </template>
      <v-card>
        <v-form @submit.prevent="submitForm">
          <v-row no-gutters>
            <v-col cols="12" md="5">
              <v-card-title>
                <span class="text-h5">{{
                  $t("message.create-item-title")
                }}</span>
              </v-card-title>
            </v-col>

            <v-col cols="12" md="5">
              <div class="grid-btn-submit">
                <div class="">
                  <div class="d-flex align-center">
                    <label class="px-2">{{ this.$t("message.publish") }}</label>
                    <v-switch v-model="form.is_active"></v-switch>
                  </div>
                </div>

                <div class="mx-2 my-2">
                  <v-btn
                    @click="dialog = false"
                    block
                    color="secondary">
                    {{ $t("message.back-button") }}
                  </v-btn>
                </div>

                <div class="mx-2 my-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    @click.prevent="submitForm"
                  >
                    {{ $t("message.submit-button") }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="5">
                  <v-row>
                    <!-- <v-col cols="12" md="3" xs="2">
                        <span>{{ $t("message.shop") }}</span>
                        </v-col>
                        <v-col cols="12" md="9" xs="2">
                        <v-autocomplete
                            :items="shopList"
                            outlined
                            v-model="form.store_id"
                            :error-messages="shopErrors"
                            @input="$v.form.store_id.$touch()"
                            @blur="$v.form.store_id.$touch()"
                            required
                            item-text="store_name"
                            item-value="id"
                        ></v-autocomplete>
                        </v-col>

                        <div class="class-horizontal-line col-12 col-md-10">
                          <span class="horizontal-line"></span>
                        </div> -->

                    <v-col
                      cols="12"
                      md="3"
                      xs="2"
                      style="display: flex; align-items: center"
                    >
                      <span class="">{{ $t("message.images") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-file-input
                        persistent-placeholder
                        height="55px"
                        v-model="form.images"
                        accept="image/*"
                        id="file-input"
                        ref="fileImg"
                        :prepend-icon="null"
                        style="cursor: pointer"
                        prepend-inner-icon="mdi-plus-circle-outline"
                        type="file"
                        @change="upload"
                        :disabled="uploadImg.length == 10"
                      ></v-file-input>
                      <p style="font-size: 12px; color: red; margin-left: 28px">
                        {{ $t("message.label-max-img-upload") }}
                      </p>
                    </v-col>

                    <!-- GRID PREVIEW IMAGE -->
                    <!-- <v-col cols="12" md="3"></v-col>
                        <v-col cols="12" md="9">
                          <div class="d-flex justify-start align-items-start">
                            <div v-for="(cardImg, idx) in 10" :key="idx">
                              <div class="card-image-preview">
                                <div v-for="(img, a) in uploadImg" :key="a">
                                  <v-img
                                    :lazy-src="img.image_url"
                                    :src="img.image_url"
                                    max-height="68px"
                                    max-width="100px"
                                    width="82px"
                                    class=""
                                  >
                                    <v-icon class="icon-close" @click.prevent="deleteImg(img.id, a)">
                                      mdi-close-circle-outline
                                    </v-icon>
                                  </v-img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col> -->

                    <template>
                      <v-container
                        class="d-flex justify-start"
                        style="max-width: 500px;"
                      >
                        <v-row class="mb-6" no-gutters justify="start">
                          <v-col cols="12" md="3"></v-col>
                          <div class="grid-image">
                            <!-- <div v-for="(cardImg, idx) in 10" :key="idx"> -->
                            <v-col v-for="(img, i) in uploadImg" :key="i">
                              <div class="card-image-preview">
                                <v-img
                                  :lazy-src="img.image_url"
                                  :src="img.image_url"
                                  height="68px"
                                  width="82px"
                                >
                                  <v-icon
                                    class="icon-close"
                                    @click.prevent="deleteImg(i, img.image_url)"
                                  >
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-img>
                              </div>
                            </v-col>
                          </div>
                          <!-- </div> -->
                        </v-row>
                      </v-container>
                    </template>

                    <v-col cols="12" md="3" xs="2">
                      <span class="label">{{ $t("message.item-code") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-text-field
                        v-model="form.item_code"
                        @input="$v.form.item_code.$touch()"
                        @blur="$v.form.item_code.$touch()"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <span>{{ $t("message.product-name") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-text-field
                        v-model="form.item_name"
                        :error-messages="productNameErrors"
                        @input="$v.form.item_name.$touch()"
                        @blur="$v.form.item_name.$touch()"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <v-col cols="12" md="3" xs="2">
                      <span class="label lable-class">{{
                        $t("message.category")
                      }}</span>
                    </v-col>
                    <!-- <v-col cols="12" md="9" xs="2">
                            <v-autocomplete
                                v-model="form.category"
                                :items="itemListCategory"
                                item-text="category_name"
                                item-value="id"
                                outlined
                                required
                                :error-messages="categoryErrors"
                                @input="$v.form.category.$touch()"
                                @blur="$v.form.category.$touch()"
                                v-on:change="checkingBulk"
                                auto-select-first
                            >
                            <template v-slot:item="{item}">
                                <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                            </template>
                            </v-autocomplete>
                        </v-col> -->
                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="form.category_parent"
                        :items="categoryListParent"
                        item-text="category_name"
                        item-value="id"
                        @change="changeCategoryParent"
                        outlined
                        required
                        :error-messages="category_parentErrors"
                        @input="$v.form.category_parent.$touch()"
                        @blur="$v.form.category_parent.$touch()"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="form.category_child"
                        :items="categoryListChild"
                        item-text="category_name"
                        item-value="id"
                        @change="changeCategoryChild"
                        outlined
                        clearable
                        required
                        :error-messages="category_childErrors"
                        @input="$v.form.category_child.$touch()"
                        @blur="$v.form.category_child.$touch()"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="form.category_grand_child"
                        :items="categoryListGrandChild"
                        item-text="category_name"
                        item-value="id"
                        outlined
                        clearable
                        required
                        :error-messages="category_grandchildErrors"
                        @input="$v.form.category_grand_child.$touch()"
                        @blur="$v.form.category_grand_child.$touch()"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <span>{{ this.$t("message.marketplace") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-select
                        v-model="form.marketplace"
                        :items="marketplace"
                        multiple
                        outlined
                        chips
                        persistent-hint
                        item-text="marketplace_name"
                        item-value="id"
                      ></v-select>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span>{{
                          $t("message.label-Tax_process_category")
                        }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-select
                          v-model="form.tax_process_category"
                          :items="taxProcessCategory"
                          :error-messages="taxCategoryErrors"
                          @input="$v.form.tax_process_category.$touch()"
                          @blur="$v.form.tax_process_category.$touch()"
                          required
                          outlined
                          item-text="text"
                          item-value="value"
                          @change="changeTaxProcessCategory"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <span>{{ $t("message.label-tax_rate") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-select
                          v-model="form.tax_rate"
                          :items="tax_rate_data"
                          :error-messages="tax_rateErrors"
                          @input="$v.form.tax_rate.$touch()"
                          @blur="$v.form.tax_rate.$touch()"
                          required
                          outlined
                          item-text="text"
                          item-value="value"
                          @change="changeTax_rate"
                          :disabled="form.tax_process_category == 3"
                        >
                        </v-select>
                      </v-col>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12" xs="2">
                        <span class="d-flex align-center">{{
                          $t("message.price")
                        }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          v-model="form.price"
                          type="number"
                          outlined

                          @change="changePrice"
                          :disabled="this.priceIsTrue == false"
                        ></v-text-field>
                      </v-col>
                      <!-- :disabled="
                                form.tax_process_category != 1
                                && form.tax_process_category != 3
                                && form.tax == ''
                                && form.price == ''
                                && this.priceIsTrue == false
                                " -->

                      <v-col cols="12" xs="2">
                        <span>{{ $t("message.tax") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          v-model="form.tax"
                          outlined
                          @change="changeTax"
                          :disabled="form.tax_process_category == 3"
                        ></v-text-field>
                      </v-col>
                    </div>
                    <v-col cols="12" md="3" xs="2">
                      <span>{{ this.$t("message.price_with_tax") }}</span>
                    </v-col>
                    <v-col cols="12" md="3" xs="2">
                      <v-text-field
                        v-model="form.price_with_tax"
                        outlined
                        @change="changePriceWithTax"
                        :disabled="this.price_w_taxIsTrue == false"
                      ></v-text-field>
                    </v-col>

                    <!-- :disabled="
                            form.tax_process_category != 2
                            && form.tax == ''
                            && form.price == ''
                            && this.price_w_taxIsTrue == false
                            " -->
                    <div>
                      <v-divider></v-divider>
                    </div>
                  </v-row>
                </v-col>

                <v-col class="d-flex justify-center">
                  <!-- <div class="class-vertical-line">
                          <span class="vertical-line"></span>
                      </div> -->
                  <v-divider vertical class="class-vertical-line"></v-divider>
                </v-col>

                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12" md="2" xs="2" class="my-4">
                      <span>{{ $t("message.description") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-textarea
                        hide-details="false"
                        v-model="form.description"
                        full-width
                        height="90px"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="2" xs="2">
                      <span>{{ $t("message.specification") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-textarea
                        v-model="form.specification"
                        hide-details="false"
                        full-width
                        height="90px"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <!-- <v-col cols="12" xs="2">
                      <span>{{ $t("message.product_dimension") }}</span>
                    </v-col>
                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span style="float: left; width: 26px">{{
                          $t("message.width")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="form.width"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span style="float: left">{{
                          $t("message.length")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="form.length"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span style="float: left">{{
                          $t("message.height")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="form.height"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="">
                        <span style="float: left">{{
                          $t("message.weight")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="form.weight"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </div> -->

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <div class="" style="width: 100px">
                          <label class="py-5">{{
                            $t("message.label-locker")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10" xs="2">
                        <v-switch v-model="form.locker"></v-switch>
                      </v-col>

                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <label class="py-5">{{
                            $t("message.contacles_delivery")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10" xs="2">
                        <v-switch
                          v-model="form.delivery"
                          required
                          :error-messages="deliveryErrors"
                          @input="$v.form.delivery.$touch()"
                          @blur="$v.form.delivery.$touch()"
                        ></v-switch>
                      </v-col>
                    </div>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span>{{ this.$t("message.stock") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          v-model="form.stock"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span>{{ $t("message.initial-stock") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          type="number"
                          v-model="form.initial_stock"
                          required
                          outlined
                          :error-messages="initial_stockErrors"
                          @input="$v.form.initial_stock.$touch()"
                          @blur="$v.form.initial_stock.$touch()"
                        ></v-text-field>
                      </v-col>
                    </div>

                    <div class="grid-two-column">
                      <v-col
                        cols="12"
                        style="display: flex; align-items: center"
                      >
                        <div class="">
                          <label class="py-8">{{
                            $t("message.reset-flag")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-switch
                          v-model="form.flag_reset"
                          label="在庫リセットを有効にすると、在庫数をワンクリックで初期在庫数にできるようになります。"
                        ></v-switch>
                      </v-col>
                    </div>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <v-col cols="12" md="2" xs="2">
                      <span>{{ $t("message.label-tags") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-text-field
                        type="text"
                        v-model="form.tag"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  minValue,
  decimal,
} from "vuelidate/lib/validators";
export default {
  name: "FormGroupItems",
  mixins: [validationMixin],
  props: ["idStore"],
  // props: {
  //   editData: Object
  // },
  validations: {
    form: {
      item_name: { required, minLength: minLength(10) },
      store_id: { required },
      category_parent: { required },
      category_child: { required },
      category_grand_child: { required },
      // category: { required },
      price: { required, numeric },
      maxQty: { required, numeric },
      tax: { required, decimal },
      initial_stock: { required },
      description: { required, minLength: minLength(10) },
      specification: { required, minLength: minLength(10) },
      images: { required },
      is_active: { required },
      item_code: { required },
      weight: { required, numeric },
      flag_reset: { required },
      width: { required, numeric },
      length: { required, numeric },
      height: { required, numeric },
      tax_rate: { required, numeric },
      tax: { required, numeric },
      tax_process_category: { required },
      locker: { required },
      stock: { required },
      marketplace: { required },
      price_with_tax: { required, numeric },
      delivery: { required },
    },
  },
  data() {
    return {
      dialog: false,
      status: false,
      data_switch_2: false,
      data_switch_3: false,
      data_switch_4: false,
      errMsg: "",
      priceIsTrue: false,
      price_w_taxIsTrue: false,
      tax_rate_isTrue: false,
      result_tax: null,
      form: {
        item_name: "",
        tag: "",
        // category: "",
        category_parent: "",
        category_child: "",
        category_grand_child: "",
        price: "",
        is_bulk: "",
        tax: "",
        initial_stock: "",
        description: "",
        specification: "",
        item_code: "",
        is_active: 0,
        delivery: 0,
        marketplace: [],
        stock: "",
        images: null,
        store_id: "",
        weight: "",
        width: "",
        length: "",
        height: "",
        flag_reset: 0,
        tax_rate: "",
        tax_process_category: "",
        price_with_tax: "",
        locker: 0,
        minimum_stock: 100,
        minimum_order: 1,
        // minQty: "",
        maxQty: "",
      },
      message: {},
    };
  },

  methods: {
    async upload(event) {
      if (this.form.images != null) {
        await this.$store.dispatch("uploadImage_module/uploadImg", {
          images: this.form.images,
          path: "itemsV2",
        });
      }
    },
    async deleteImg(i, imageUrl) {
      await this.$store.dispatch("uploadImage_module/deleteStateItem", {
        index: i,
      });
      await this.$store.dispatch("uploadImage_module/deleteImgBucket", {
        path: imageUrl,
      });
    },
    async fetchModalCreate() {
      await this.$store.dispatch("marketplace_module/fetchMarketPlace");
      await this.$store.dispatch("itemList_module/fetchCategoryListParent");
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id: 0,
      });
    },
    fetchShopList() {
      return this.$store.dispatch("shop_module/fetchShopAll");
    },
    // HIDE OLD CATEGORY
    // fetchItemListCategory() {
    //   return this.$store.dispatch("itemList_module/fetchItemListCategory");
    // },
    // async dispatchCategoryListParent() {
    //   await this.$store.dispatch("itemList_module/fetchCategoryListParent")
    // },
    // async dispatchCategoryListNew() {
    //   await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
    //     parent_id: 0
    //   })
    // },
    async changeCategoryParent() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id:
          this.form.category_parent === null ? "" : this.form.category_parent,
      });
    },
    async changeCategoryChild() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id:
          this.form.category_child === null ? "" : this.form.category_child,
      });
    },
    checkingBulk(val) {
      this.itemListCategory.forEach((el) => {
        if (el.id === val) {
          this.form.is_bulk = el.is_bulk;
        }
      });
      return this.form.is_bulk;
    },
    changeTaxProcessCategory() {
      if (this.form.tax_process_category == 1) {
        this.priceIsTrue = true;
        // this.tax_rate_isTrue = false
        this.price_w_taxIsTrue = false;
        this.form.tax = "";
        this.form.price = "";
        this.form.price_with_tax = "";
      } else if (this.form.tax_process_category == 2) {
        this.price_w_taxIsTrue = true;
        // this.tax_rate_isTrue = false
        this.priceIsTrue = false;
        this.form.tax = "";
        this.form.price = "";
        this.form.price_with_tax = "";
      } else if (this.form.tax_process_category == 3) {
        this.priceIsTrue = true;
        this.price_w_taxIsTrue = false;
        this.form.tax_rate = 0;
        this.form.tax = 0;
        this.form.price = "";
        this.form.price_with_tax = "";
      }
    },
    changeTax_rate() {
      this.calcTax();
      this.calcPrice();
      this.calcPriceWithTax();
    },
    changePrice() {
      this.calcTax();
      this.calcPriceWithTax();
    },
    changeTax() {
      this.calcPrice();
      this.calcPriceWithTax();
    },
    changePriceWithTax() {
      this.calcTax();
      this.calcPrice();
    },
    calcTax() {
      if (this.form.tax_process_category == 1) {
        if (this.form.tax_rate == 8) {
          this.form.tax = Math.floor(this.form.price * 8) / 100;
        } else if (this.form.tax_rate == 10) {
          this.form.tax = Math.floor(this.form.price * 10) / 100;
        }
      } else if (this.form.tax_process_category == 2) {
        if (this.form.tax_rate == 8) {
          this.form.tax = Math.ceil( Math.floor(
            (((this.form.price_with_tax * 100) / (100 + 8)) * 8)
          ) / 100);
        } else if (this.form.tax_rate == 10) {
          this.form.tax = Math.ceil( Math.floor(
            (((this.form.price_with_tax * 100) / (100 + 10)) * 10)
          ) / 100);
        }
      } else if (this.form.tax_process_category == 3) {
        this.form.tax = 0;
      }
    },
    calcPrice() {
      if (this.form.tax_process_category == 2) {
        this.form.price = isNaN(
          parseFloat(this.form.price_with_tax) - parseFloat(this.form.tax)
        )
          ? 0
          : parseFloat(this.form.price_with_tax) - parseFloat(this.form.tax);
      }
    },
    calcPriceWithTax() {
      if (this.form.tax_process_category == 1) {
        this.form.price_with_tax = isNaN(
          parseFloat(this.form.price) + parseFloat(this.form.tax)
        )
          ? 0
          : parseFloat(this.form.price) + parseFloat(this.form.tax);
      } else if (this.form.tax_process_category == 3) {
        this.form.price_with_tax = this.form.price;
      }
    },
    async submitForm() {
      if (this.form.is_active == true) {
        this.form.is_active = 1;
      } else {
        this.form.is_active = 0;
      }

      if (this.form.locker == true) {
        this.form.locker = 1;
      } else {
        this.form.locker = 0;
      }

      if (this.form.delivery == true) {
        this.form.delivery = 1;
      } else {
        this.form.delivery = 0;
      }

      if (this.form.flag_reset == true) {
        this.form.flag_reset = 1;
      } else {
        this.form.flag_reset = 0;
      }

      this.$v.$touch();
      let payload = {
        item_name: this.form.item_name,
        // category_id: this.form.category,
        category_parent_id: this.form.category_parent,
        category_child_id: this.form.category_child,
        category_grandchild_id: this.form.category_grand_child,
        price: this.form.price,
        tax: this.form.tax,
        initial_stock: parseInt(this.form.initial_stock),
        description: this.form.description,
        specification: this.form.specification,
        store_id: this.idStore,
        item_code: this.form.item_code,
        images: this.uploadImg,
        flag_reset:
          this.form.flag_reset == undefined || null ? "" : this.form.flag_reset,
        weight: this.form.weight,
        width: this.form.width,
        length: this.form.length,
        height: this.form.height,
        marketplace: this.form.marketplace,
        // price_with_tax: this.form.price_with_tax,
        item_shipping_type: this.form.delivery,
        stock: this.form.stock,
        tag: this.form.tag,
        minimum_stock: this.form.minimum_stock,
        minimum_order: this.form.minimum_order,
        is_active: this.form.is_active,
        tax_rate: this.form.tax_rate,
        tax_category: this.form.tax_process_category,
        is_locker: this.form.locker,
        max_qty: this.form.maxQty,
      };
      // console.log(payload, 'payload');
      await this.$store.dispatch("itemList_module/addItemList", payload);
    },
  },
  created() {
    // this.fetchModalCreate();
    // this.fetchShopList();
    // this.dispatchCategoryListParent();
    // this.dispatchCategoryListNew();
    // this.fetchItemListCategory();
    // this.fetchMarketplace();
  },
  mounted() {
    this.$store.dispatch("uploadImage_module/clearImg");
  },
  computed: {
    marketplace() {
      return this.$store.state.marketplace_module.marketplace_list;
    },
    uploadImg() {
      return this.$store.state.uploadImage_module.resLink;
    },
    taxProcessCategory() {
      return [
        { text: "外税", value: 1 },
        { text: "内税", value: 2 },
        { text: "非課税", value: 3 },
      ];
    },
    tax_rate_data() {
      if (this.form.tax_process_category != 3) {
        return [
          { text: "8%", value: 8 },
          { text: "10%", value: 10 },
        ];
      } else {
        return [
          { text: "-", value: 0 },
          { text: "8%", value: 8 },
          { text: "10%", value: 10 },
        ];
      }
    },
    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    categoryListParent() {
      return this.$store.state.itemList_module.categoryParent;
    },
    categoryListChild() {
      return this.$store.state.itemList_module.categoryChild;
    },
    categoryListGrandChild() {
      return this.$store.state.itemList_module.categoryGrandChild;
    },

    // HIDE
    // itemListCategory() {
    //   return this.$store.state.itemList_module.itemListCategory;
    // },

    // err message
    shopErrors() {
      const errors = [];
      if (!this.$v.form.store_id.$dirty) return errors;
      !this.$v.form.store_id.required &&
        errors.push(this.$t("message.err-msg-shop"));
      return errors;
    },
    tax_rateErrors() {
      const errors = [];
      if (!this.$v.form.tax_rate.$dirty) return errors;
      !this.$v.form.tax_rate.numeric &&
        errors.push(this.$t("message.err-msg-tax_rate-numeric"));
      !this.$v.form.tax_rate.required &&
        errors.push(this.$t("message.err-msg-tax_rate-required"));
      if (this.form.tax_rate < 0) {
        errors.push(this.$t("message.err-msg-tax_rate-not-zero"));
      }
      // if (this.form.tax_rate > 10) {
      //   errors.push(this.$t("フィールド税率が10を超える"));
      // }
      return errors;
    },
    deliveryErrors() {
      const errors = [];
      if (!this.$v.form.delivery.$dirty) return errors;
      !this.$v.form.delivery.required &&
        errors.push("The switch shipping type is required!");
      return errors;
    },
    item_codeErrors() {
      const errors = [];
      if (!this.$v.form.item_code.$dirty) return errors;
      !this.$v.form.item_code.required &&
        errors.push(this.$t("message.err-msg-item-code"));
      return errors;
    },
    productNameErrors() {
      const errors = [];
      if (!this.$v.form.item_name.$dirty) return errors;
      !this.$v.form.item_name.required &&
        errors.push(this.$t("message.err-msg-item-name"));
      return errors;
    },
    category_parentErrors() {
      const errors = [];
      if (!this.$v.form.category_parent.$dirty) return errors;
      if (
        !this.$v.form.category_parent.required &&
        !this.$v.form.category_child.required &&
        !this.$v.form.category_grand_child.required
      ) {
        errors.push(this.$t("message.label-category_parent"));
      }
      return errors;
    },
    category_childErrors() {
      const errors = [];
      if (!this.$v.form.category_child.$dirty) return errors;
      if (
        !this.$v.form.category_child.required &&
        !this.$v.form.category_parent.required &&
        !this.$v.form.category_grand_child.required
      ) {
        errors.push(this.$t("message.label-category_child"));
      }
      return errors;
    },
    category_grandchildErrors() {
      const errors = [];
      if (!this.$v.form.category_grand_child.$dirty) return errors;
      if (
        !this.$v.form.category_grand_child.required &&
        !this.$v.form.category_parent.required &&
        !this.$v.form.category_child.required
      ) {
        errors.push(this.$t("message.label-category_grand_child"));
      }
      return errors;
    },
    // HIDE
    // categoryErrors() {
    //   const errors = [];
    //   if (!this.$v.form.category.$dirty) return errors;
    //   !this.$v.form.category.required &&
    //     errors.push(this.$t("message.err-msg-category"));
    //   return errors;
    // },
    taxCategoryErrors() {
      const errors = [];
      if (!this.$v.form.tax_process_category.$dirty) return errors;
      !this.$v.form.tax_process_category.required &&
        errors.push(this.$t("message.err-msg-taxCategory"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      !this.$v.form.price.numeric &&
        errors.push(this.$t("message.err-msg-price-num"));
      // !this.$v.form.price.required &&
      //   errors.push(this.$t("message.err-msg-price"));
      return errors;
    },
    price_with_taxErrors() {
      const errors = [];
      if (!this.$v.form.price_with_tax.$dirty) return errors;
      !this.$v.form.price_with_tax.numeric &&
        errors.push(this.$t("message.err-msg-price-num"));
      // !this.$v.form.price_with_tax.required &&
      //   errors.push(this.$t("message.err-msg-price"));
      return errors;
    },
    taxErrors() {
      const errors = [];
      if (!this.$v.form.tax.$dirty) return errors;
      !this.$v.form.tax.numeric &&
        errors.push(this.$t("message.err-msg-tax-num"));
      // !this.$v.form.tax.required && errors.push(this.$t("message.err-msg-tax"));
      return errors;
    },
    initial_stockErrors() {
      const errors = [];
      if (!this.$v.form.initial_stock.$dirty) return errors;
      !this.$v.form.initial_stock.required &&
        errors.push(this.$t("message.err-msg-stock"));
      return errors;
    },
  },
};
</script>

<style>
.grid-btn-submit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, auto));
}
.grid-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, auto));
  /* column-gap: 1rem; */
}
.grid-two-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, auto));
}
.grid-image {
  /* display: flex; */
  justify-content: flex-start;
  flex-direction: row;
  /* height: auto; */
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, auto));
  column-gap: 1.5rem; */
  /* row-gap: 0.8rem; */
  /* max-width: 1000px; */
  /* column-gap: 1rem; */
  margin-bottom: 5px;
}
.icon-close {
  /* position: absolute; */
  /* z-index: -1; */
}
.card-image-preview {
  width: 82px;
  height: 70px;
  border: 1px solid black;
  border-radius: 5px;
}
.image-class {
  /* width: 50%; */
  /* height: 10vh; */
}
/* .custom-dialog.v-dialog{
    margin-left: 15rem;
} */
.grid-form {
  margin-left: 20px;
}
.vertical-line {
  border-left: 1px solid black;
  height: 830px;
  position: absolute;
  left: 50%;
  top: 10%;
}
.horizontal-line {
  border-bottom: 1px solid black;
  width: 38rem;
  position: absolute;
  /* top: 35%; */
  /* top: 42%; */
  margin: auto;
}
.horizontal-line-column-two {
  border-bottom: 1px solid black;
  width: 39rem;
  position: absolute;
  /* top: 85%; */
  /* top: 92%; */
  margin: auto;
  left: 52%;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
@media screen and (max-width: 412px) {
  .class-vertical-line {
    display: none;
  }
  .class-horizontal-line {
    width: 20rem;
  }
  .horizontal-line {
    width: 18rem;
  }
  .horizontal-line-column-two {
    width: 20rem;
    left: 5%;
  }
}
/* .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 2px;
} */
</style>
