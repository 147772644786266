<template>
    <v-container class="container-filter">
        <v-form @submit.prevent="submitFilterItem">
            <v-row no-gutters align="center" justify="center">
                <div class="ml-4" style="color: white">
                    <h6>{{ $t("message.title-filter-itemList") }}</h6>
                </div>

                <v-col cols="10">
                    <v-row>
                        <!-- GRID COLUMN 1 -->
                        <v-col cols="4" md="4">
                          <v-row no-gutters justify="center">
                            <v-col md="4" xs="2">
                              <label style="color: white">{{ $t("message.shop") }}</label>
                            </v-col>
                            <v-col md="7" xs="2" class="">
                              <v-autocomplete
                              @change="getStoreList"
                              :items="shopList"
                              solo
                              v-model="filterItem.storeId"
                              item-text="store_name"
                              item-value="id"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>

                            <!-- HIDE CATEOGRY ONE INPUT -->
                            <!-- <v-row no-gutters justify="center">
                                <v-col md="4" xs="2">
                                    <label style="color: white">{{ $t("message.category") }}</label>
                                </v-col>
                                <v-col md="8" xs="2" class="">
                                    <v-autocomplete
                                        class="mt-1 font-size filter-input-class"
                                        solo
                                        clearable
                                        v-model="filterItem.categoryId"
                                        :items="categoryList"
                                        item-text="category_name"
                                        item-value="id"
                                        persistent-hint
                                        auto-select-first
                                        :menu-props="{ maxWidth: '50%' }"
                                    >
                                        <template v-slot:item="{ item }">
                                        <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row> -->

                            <v-row no-gutters justify="center">
                              <v-col md="4" xs="2" style="display: flex; flex-direction: column; justify-content: center; margin-top: -20px">
                                  <label style="color: white">{{ $t("message.current-stock") }}</label>
                              </v-col>
                              <v-col md="7" xs="2" class="">
                                <v-row no-gutters>
                                  <v-col>
                                    <v-text-field
                                      type="number"
                                      class="grid-input-range"
                                      v-model="filterItem.stockFrom"
                                      solo
                                    />
                                  </v-col>
                                  <v-col>
                                    <div class="text-center mb-1" style="">
                                      <v-icon size="14" style="color: white" >mdi-tilde</v-icon>
                                    </div>
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      type="number"
                                      class="grid-input-range"
                                      v-model="filterItem.stockTo"
                                      solo
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                        </v-col>

                        <!-- GRID COLUMN 2 -->
                        <v-col cols="4" md="4">
                            <v-row no-gutters justify="center">
                                <v-col md="4" xs="2">
                                    <label style="color: white">{{ $t("message.item-code") }}</label>
                                </v-col>
                                <v-col md="7" xs="2" class="">
                                    <v-text-field
                                        v-model="filterItem.item_code"
                                        clearable
                                        solo
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row no-gutters justify="center">
                                <v-col md="4" xs="2">
                                    <label style="color: white">{{ $t("message.marketplace") }}</label>
                                </v-col>
                                <v-col md="7" xs="2" class="">
                                    <v-autocomplete
                                    :items="marketplace"
                                    solo
                                    v-model="filterItem.marketplaceId"
                                    item-text="marketplace_name"
                                    item-value="id"
                                    clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        
                        </v-col>

                        <v-col cols="4" md="4">
                          <v-row no-gutters justify="center">
                            <v-col md="4" xs="2">
                              <label style="color: white">{{ $t("message.status") }}</label>
                            </v-col>
                            <v-col md="7" xs="2" class="">
                              <v-select
                                class="mt-1 font-size filter-input-class"
                                solo
                                clearable
                                v-model="filterItem.selectStatus"
                                :items="statusItems"
                                item-text="text"
                                item-value="id"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row no-gutters justify="center">
                            <v-col md="4" xs="2">
                              <label style="color: white">{{ $t("message.product-name") }}</label>
                            </v-col>
                            <v-col md="7" xs="2" class="">
                              <v-text-field
                                v-model="filterItem.productName"
                                solo
                                clearable
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <!-- GRID COLUMN 3 -->
                        <v-col cols="12" md="7" style="margin-left: 3px">
                            <!-- <v-row no-gutters justify="center">
                                <v-col md="3" xs="2">
                                    <label style="color: white">product name</label>
                                </v-col>
                                <v-col md="6" xs="2" class="">
                                    <v-text-field
                                        solo
                                    ></v-text-field>
                                </v-col>
                            </v-row> -->

                            <!-- <v-row no-gutters justify="center">
                                <v-col md="5" xs="2">
                                    <label style="color: white">{{ $t("message.status") }}</label>
                                </v-col>
                                <v-col md="6" xs="2" class="">
                                    <v-select
                                        class="mt-1 font-size filter-input-class"
                                        solo
                                        clearable
                                        v-model="filterItem.selectStatus"
                                        :items="statusItems"
                                        item-text="text"
                                        item-value="id"
                                        persistent-hint
                                    ></v-select>
                                </v-col>
                            </v-row> -->

                            <!-- <v-row no-gutters justify="center">
                              <v-col cols="12" md="2" xs="2" style="display: flex; flex-direction: column; justify-content: center; margin-top: -20px">
                                  <label style="color: white">{{ $t("message.category") }}</label>
                              </v-col>
                              <v-col cols="12" xs="2" class="">
                                <v-row no-gutters>
                                  <v-col md="3" style="margin-right: 5px">
                                    <v-autocomplete
                                      v-model="categoryParent"
                                      @change="changeCategoryParent"
                                      solo
                                      :items="listCategoryParent"
                                      item-text="category_name"
                                      item-value="id"
                                      clearable
                                    >
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col md="3" style="margin: 0 5px">
                                    <v-autocomplete
                                      v-model="categoryChild"
                                      solo
                                      @change="changeCategoryChild"
                                      :items="categoryListChild"
                                      item-text="category_name"
                                      item-value="id"
                                      clearable
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col md="3" style="margin-left: 5px">
                                    <v-autocomplete
                                      v-model="categoryGrandChild"
                                      solo
                                      :items="categoryListGrandChild"
                                      item-text="category_name"
                                      item-value="id"
                                      clearable
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row> -->

                            <v-row no-gutters justify="center">
                              <v-col md="2" xs="2" style="margin-right: 12px">
                                <label style="color: white">{{ $t("message.category") }}</label>
                              </v-col>
                              <v-col md="3" xs="2" style="margin-right: 5px">
                                <v-autocomplete
                                v-model="filterItem.categoryParent"
                                @change="changeCategoryParent"
                                solo
                                :items="categoryListParent"
                                item-text="category_name"
                                item-value="id"
                                clearable
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col md="3" xs="2" style="margin: 0 5px">
                                <v-autocomplete
                                v-model="filterItem.categoryChild"
                                solo
                                @change="changeCategoryChild"
                                :items="categoryListChild"
                                item-text="category_name"
                                item-value="id"
                                clearable
                                ></v-autocomplete>
                              </v-col>
                              <v-col md="3" xs="2" style="margin-left: 5px">
                                <v-autocomplete
                                v-model="filterItem.categoryGrandChild"
                                solo
                                :items="categoryListGrandChild"
                                item-text="category_name"
                                item-value="id"
                                clearable
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            
                            
                        </v-col>
                    </v-row>         
                    
                </v-col>
                
                <v-col class="pr-2 pb-5 ps-8" style="margin-top: -3rem" cols="10" md="1" xs="6" >
                    <v-btn style="" type="submit" @click.prevent="submitFilterItem" rounded color="primary">
                    {{ $t("message.search") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
export default {
  name: "FilterItemList",
  data() {
    return {
        filterItem: {
          storeId: "",
          item_code: "",
          stockFrom: "",
          stockTo: "",
          marketplaceId: "",
          productName: "",
          selectStatus: "",
          categoryParent: "",
          categoryChild: "",
          categoryGrandChild: "",
        },
        page: 1,
        stateStore: "",
        stateStatus: "",
        listCategoryParent: []
    }
  },
  watch: {
    initFilter: {
      handler() {
        this.stateStore = this.$store.state.itemList_module.selectedStore;
        this.stateStatus = this.$store.state.itemList_module.selectedStatus;
        this.page = parseInt(this.$store.state.itemList_module.page);
      }
    }
  },
  methods: {
    
    getStoreList() {
      return this.$store.dispatch("itemList_module/addStateStoreId", this.filterItem.storeId)
    },
    submitFilterItem() {
      
      this.dispatchFilterItems();
    },
    dispatchFilterItems() {
        this.$store.dispatch("itemList_module/fetchItemList", {
        item_code: 
          this.filterItem.item_code === null ? "" : this.filterItem.item_code,
        selected_store: 
          this.filterItem.storeId === null ? "" : this.filterItem.storeId,
        item_name: 
          this.filterItem.productName === null ? "" : this.filterItem.productName,
        selected_status:
          this.filterItem.selectStatus === null ? "" : this.filterItem.selectStatus,
        stock_from:
          this.filterItem.stockFrom === null ? "" : this.filterItem.stockFrom,
        marketplace_id: 
          this.filterItem.marketplaceId === null ? "" : this.filterItem.marketplaceId,
        stock_to: 
          this.filterItem.stockTo === null ? "" : this.filterItem.stockTo,
        
        category_parent:
          this.filterItem.categoryParent === null ? "" : this.filterItem.categoryParent,
        category_child:
          this.filterItem.categoryChild === null ? "" : this.filterItem.categoryChild,
        category_grand_child:
          this.filterItem.categoryGrandChild === null ? "" : this.filterItem.categoryGrandChild,
        
        page: 1,
      });
    },
    async initFilter() {
        if(this.stateStore === "" && this.stateStatus === "" ) {
          await this.$store.dispatch("shop_module/fetchShopAll");
          let store = this.$store.state.shop_module.shopAllResult;
          this.filterItem.storeId = store[0].id
          this.$store.dispatch("itemList_module/addStateStoreId", this.filterItem.storeId)
          let statusValue = this.statusItems
          this.filterItem.selectStatus = statusValue[0].id

          

          this.filterItem.productName = this.$store.state.itemList_module.itemName;
          this.filterItem.stockFrom = this.$store.state.itemList_module.stockFrom;
          this.filterItem.stockTo = this.$store.state.itemList_module.stockTo;
          this.filterItem.item_code = this.$store.state.itemList_module.itemCode;
          this.filterItem.categoryParent = this.$store.state.itemList_module.categoryParentId;
          this.filterItem.categoryChild = this.$store.state.itemList_module.categoryChildId;
          this.filterItem.categoryGrandChild = this.$store.state.itemList_module.categoryGrandChildId;
          this.page = parseInt(this.$store.state.itemList_module.page);
          await this.$store.dispatch("itemList_module/fetchItemList", {
            item_code:
              this.filterItem.item_code === null ? "" : this.filterItem.item_code,
            selected_store:
              this.filterItem.storeId === null ? "" : this.filterItem.storeId,
            item_name:
              this.filterItem.productName === null ? "" : this.filterItem.productName,
            selected_status:
              this.filterItem.selectStatus === null ? "" : this.filterItem.selectStatus,
            stock_from:
              this.filterItem.stockFrom === null ? "" : this.filterItem.stockFrom,
            marketplace_id:
              this.filterItem.marketplaceId === null ? "" : this.filterItem.marketplaceId,
            stock_to:
              this.filterItem.stockTo === null ? "" : this.filterItem.stockTo,
            
            category_parent:
              this.filterItem.categoryParent === null ? "" : this.filterItem.categoryParent,
            category_child:
              this.filterItem.categoryChild === null ? "" : this.filterItem.categoryChild,
            category_grand_child:
              this.filterItem.categoryGrandChild === null ? "" : this.filterItem.categoryGrandChild,
            page: this.page
            })
            
          } else {

          this.filterItem.storeId = this.$store.state.itemList_module.selectedStore;
          this.filterItem.selectStatus = this.$store.state.itemList_module.selectedStatus;
          this.filterItem.productName = this.$store.state.itemList_module.itemName;
          this.filterItem.stockFrom = this.$store.state.itemList_module.stockFrom;
          this.filterItem.stockTo = this.$store.state.itemList_module.stockTo;
          this.filterItem.item_code = this.$store.state.itemList_module.itemCode;
          this.filterItem.categoryParent = this.$store.state.itemList_module.categoryParentId;
          this.filterItem.categoryChild = this.$store.state.itemList_module.categoryChildId;
          this.filterItem.categoryGrandChild = this.$store.state.itemList_module.categoryGrandChildId;
          this.page = parseInt(this.$store.state.itemList_module.page);
        }

        this.$emit("export", this.filterItem)
    },
    fetchMarketplace() {
      return this.$store.dispatch("marketplace_module/fetchMarketPlace")
    },
    async dispatchCategoryListParent() {
      await this.$store.dispatch("itemList_module/fetchCategoryListParent")
    },
    async changeCategoryParent() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id: this.filterItem.categoryParent === null ? "" : this.filterItem.categoryParent,
      })
    },
    async changeCategoryChild() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id: this.filterItem.categoryChild === null ? "" : this.filterItem.categoryChild,
      })
    },
    async dispatchCategoryListNew() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id: 0
      })
    }
  },
  beforeMount() {
    this.initFilter()
  },
  mounted() {
    // this.initFilter()
  },
  beforeCreate() {
    // this.initFilter()
  },
  created() {
    // this.initFilter()
    // this.dispatchCategoryList();
    this.fetchMarketplace();
    this.dispatchCategoryListParent();
    // this.dispatchCategoryListChild();
    // this.dispatchCategoryLisGrandChild();
    this.dispatchCategoryListNew()
  },
  computed: {
    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    categoryListParent() {
      return this.$store.state.itemList_module.categoryParent
    },
    categoryListChild() {
      return this.$store.state.itemList_module.categoryChild
    },
    categoryListGrandChild() {
      return this.$store.state.itemList_module.categoryGrandChild
    },
    // categoryList() {
    //   return this.$store.state.itemList_module.categoryList;
    // },
    marketplace() {
      return this.$store.state.marketplace_module.marketplace_list
    },
    statusItems() {
      return [
        { id: "1", text: this.$t("message.active") },
        { id: "0", text: this.$t("message.inactive") },
      ];
    },
  }
};
</script>

<style>
.grid-input-range {
    width: 70px;
}
.grid-input-filter {
    /* background-color: #869ab8; */
}
.form-filter-itemlist {
    margin: 5px 5px;
    display: grid;
    grid-template-columns: 8rem repeat(auto-fit, minmax(5rem, 1fr));
    column-gap: 5px;
}
.form-label {
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    width: 8rem;
    margin-top: 5px;
}



.container-filter {
  background-color: #869ab8;
  width: 100%;
  height: auto;
}
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.form-inline {
  justify-content: flex-end;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;

}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-input__append-outer {
  margin-top: 3px !important;
}
.v-input__prepend-inner  {
  margin-top: 3px !important;
}
.v-input__prepend-outer {
  margin-top: 3px !important;
}
</style>
