<template>
  <div>
    <div class="my-4 mx-4">
      <v-row no-gutters>
        <v-col cols="12" md="7" sm="7">
          <h2>
            {{ $t("message.item-list-title") }}
          </h2>
        </v-col>
        <v-col cols="12" md="5" sm="5">
          <div class="d-flex justify-end">
            <div class="mx-4">
              <FormGroupItems :idStore="stateStoreId" />
            </div>
            <div class="mx-2">
              <router-link to="/addItemCsv">
                <v-btn rounded color="primary">
                  <v-icon left style="padding: 0px 5px 0px 10px">
                    mdi-arrow-down-thin
                  </v-icon>
                  {{ $t("message.import-file-csv") }}
                </v-btn>
              </router-link>
            </div>

            <div class="mx-2">
              <v-btn
                style=""
                @click="fetchExportItem"
                rounded
                color="primary"
                block
              >
                <v-icon left>
                  mdi-arrow-up-thin
                </v-icon>
                {{ $t("message.export") }}
              </v-btn>
            </div>

            <div>
              <router-link to="#" style="text-decoration: none">
                <v-btn rounded color="secondary">
                  {{ $t("message.reset") }}
                </v-btn>
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <FilterItemList @export="exportItems" />
    </div>

    <v-container>
      <div>
        <v-pagination
          v-if="!isLength"
          v-model="page"
          :length="lengthPage"
          :total-visible="7"
          @input="changePage"
          depressed
        ></v-pagination>
      </div>

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        disable-sort
        class="elavation-0"
        :server-items-length="itemTotal"
        :options.sync="option"
        :page="currentPage"
        :items-per-page="itemPerPage"
        :footer-props="{ 'items-per-page-options': [itemPerPage] }"
        :hide-default-footer="true"
        :hide-default-header="true"
      >
        <template
          v-slot:header="{ props }"
          style="min-height: 50px; overflow: auto"
        >
          <th class="custom-th text-center">
            {{ props.headers[0].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[1].text }}
          </th>
          <th class="custom-th text-center" style="width: 15%">
            {{ props.headers[2].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[3].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[4].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[5].text }}
          </th>
          <th class="custom-th text-center" style="width: 5%">
            {{ props.headers[6].text }}
          </th>
          <th class="custom-th text-center" style="width: 10%">
            {{ props.headers[7].text }}
          </th>
          <th class="custom-th text-center" style="width: 5%">
            {{ props.headers[8].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[9].text }}
          </th>
          <th class="custom-th text-center">
            <!-- {{ props.headers[10].text }} -->
          </th>
        </template>

        <!-- imageproduct itemlist loop -->
        <template v-slot:[`item.image_url`]="{ item }" class="text-center">
          <div class="d-flex justify-center">
            <v-img
              :lazy-src="item.image_url ? item.image_url : notFound"
              :src="item.image_url ? item.image_url : notFound"
              max-height="150"
              max-width="80px"
              style="margin: 1em 0 1em 0"
            ></v-img>
          </div>
        </template>

        <!-- PRICE_W_TAX -->
        <template v-slot:[`item.price_w_tax`]="{ item }" style="margin: auto">
          <div>
            <span>{{
              isNaN(parseFloat(item.price) + parseFloat(item.tax))
                ? 0
                : parseFloat(item.price) + parseFloat(item.tax)
            }}</span>
          </div>
        </template>

        <!-- PUBLISH ACTION SWITCH -->
        <template v-slot:[`item.is_publish`]="{ item }">
          <v-row>
            <v-col>
              <v-switch
                style="margin-top: 8px"
                :input-value="item.is_active"
                color="primary"
                @click.stop="getPublish(item)"
              ></v-switch>
            </v-col>
          </v-row>
        </template>

        <!-- action button itemlist loop -->
        <template v-slot:[`item.actions`]="{ item }">
          <EditFormItem :editData="item.id" />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import EditFormItem from "../../components/developmentv2/EditFormItem.vue";
import moneyFormat from "../../helpers/moneyFormat";
import FormGroupItems from "../../components/developmentv2/FormGroupItems.vue";
import FilterItemList from "../../components/developmentv2/FilterItemList.vue";
export default {
  name: "ItemListManagement",
  components: {
    FilterItemList,
    FormGroupItems,
    EditFormItem,
  },
  data() {
    return {
      notFound: "",
      page: 1,
      option: {},
      ex11: false,
      storeId: "",
      tempExportItem: null,
      isLoading: false,
      data_filter: [],
      filterItem: {
        storeId: "",
        item_code: "",
        stockFrom: "",
        stockTo: "",
        marketplaceId: "",
        productName: "",
        selectStatus: "",
        categoryParent: "",
        categoryChild: "",
        categoryGrandChild: "",
      },
    };
  },
  watch: {
    itemList: {
      handler() {
        this.page = parseInt(this.$store.state.itemList_module.page);
        this.filterItem.storeId = this.$store.state.itemList_module.selectedStore;
        this.filterItem.selectStatus = this.$store.state.itemList_module.selectedStatus;
        this.filterItem.productName = this.$store.state.itemList_module.itemName;
        this.filterItem.stockFrom = this.$store.state.itemList_module.stockFrom;
        this.filterItem.stockTo = this.$store.state.itemList_module.stockTo;
        this.filterItem.item_code = this.$store.state.itemList_module.itemCode;
        this.filterItem.categoryParent = this.$store.state.itemList_module.categoryParentId;
        this.filterItem.categoryChild = this.$store.state.itemList_module.categoryChildId;
        this.filterItem.categoryGrandChild = this.$store.state.itemList_module.categoryGrandChildId;
      },
      deep: true,
    },
  },
  beforeMount() {
    // this.$store.dispatch("itemList_module/clearStateItemList")
    // this.exportItems()
  },
  mounted() {
    // this.fetchItem()
    this.$store.dispatch("uploadImage_module/clearImg");
  },
  created() {
    // this.fetchItem();
    // this.initStore();
    // this.fetchShopDefault();
    // this.fetchItemByStore();
  },
  methods: {
    async getPublish(item) {
      await this.$store.dispatch("itemList_module/setStatusItem", item.id);
    },
    fetchExportItem() {
      this.$store.dispatch("itemList_module/exportItems", {
        item_code:
          this.tempExportItem.item_code === null
            ? ""
            : this.tempExportItem.item_code,
        selected_store:
          this.tempExportItem.storeId === null
            ? ""
            : this.tempExportItem.storeId,
        item_name:
          this.tempExportItem.productName === null
            ? ""
            : this.tempExportItem.productName,
        status:
          this.tempExportItem.selectStatus === null
            ? ""
            : this.tempExportItem.selectStatus,
        stock_from:
          this.tempExportItem.stockFrom === null
            ? ""
            : this.tempExportItem.stockFrom,
        stock_to:
          this.tempExportItem.stockTo === null
            ? ""
            : this.tempExportItem.stockTo,
        marketplace_id:
          this.tempExportItem.marketplaceId === null
            ? ""
            : this.tempExportItem.marketplaceId,
        // categoryId:
        //   this.tempExportItem.categoryId === null ? "" : this.tempExportItem.categoryId,
        category_parent:
          this.tempExportItem.categoryParent === null
            ? ""
            : this.tempExportItem.categoryParent,
        category_child:
          this.tempExportItem.categoryChild === null
            ? ""
            : this.tempExportItem.categoryChild,
        category_grand_child:
          this.tempExportItem.categoryGrandChild === null
            ? ""
            : this.tempExportItem.categoryGrandChild,
      });
    },
    exportItems(item) {
      this.storeId = item.store_id;
      // this.data_filter = item
      this.tempExportItem = item;
      // this.fetchItem(item)
    },
    changePage(event) {
      return this.$store.dispatch("itemList_module/fetchItemList", {
        page: event,
      });
    },
    async fetchItem() {
      this.isLoading = true;
      let pageInit = parseInt(this.$store.state.itemList_module.page);
      this.page = parseInt(this.$store.state.itemList_module.page);
      // this.$store.dispatch("itemList_module/fetchItemList", { page: pageInit });
      // console.log(this.filterItem, 'filter');
      // if(item) {
      // console.log('before HIT API');
      // await this.$store.dispatch("itemList_module/fetchItemList", {
      // item_code:
      //   this.filterItem.item_code === undefined || null ? "" : this.filterItem.item_code,
      // selected_store:
      //   this.filterItem.storeId === undefined || null ? "" : this.filterItem.storeId,
      // item_name:
      //   this.filterItem.productName === undefined || null ? "" : this.filterItem.productName,
      // selected_status:
      //   this.filterItem.selectStatus === undefined || null ? "" : this.filterItem.selectStatus,
      // stock_from:
      //   this.filterItem.stockFrom === undefined || null ? "" : this.filterItem.stockFrom,
      // marketplace_id:
      //   this.filterItem.marketplaceId === undefined || null ? "" : this.filterItem.marketplaceId,
      // stock_to:
      //   this.filterItem.stockTo === undefined || null ? "" : this.filterItem.stockTo,

      // category_parent:
      //   this.filterItem.categoryParent === undefined || null ? "" : this.filterItem.categoryParent,
      // category_child:
      //   this.filterItem.categoryChild === undefined || null ? "" : this.filterItem.categoryChild,
      // category_grand_child:
      //   this.filterItem.categoryGrandChild === undefined || null ? "" : this.filterItem.categoryGrandChild,
      // page: pageInit
      // })
      // console.log('after HIT API');
      // }
      this.isLoading = false;
    },
    editItem(paramsId) {
      this.$router.push(`/editItem/${paramsId}`);
    },
  },
  computed: {
    // shopList() {
    //   return this.$store.state.shop_module.shopAllResult;
    // },
    stateStoreId() {
      return this.$store.state.itemList_module.storeIdform;
    },
    isLength() {
      return this.$store.state.itemList_module.is_length;
    },
    lengthPage() {
      return this.$store.state.itemList_module.length_page;
    },
    itemList() {
      let itemData = this.$store.state.itemList_module.itemList;
      if (!itemData) {
        return 0;
      } else {
        itemData.map((el) => {
          el.price = moneyFormat(parseInt(el.price));
          this.status = el.is_active;
        });
      }
      return itemData;
    },
    itemTotal() {
      let resp = this.$store.state.itemList_module.itemListHeader;
      if (resp.total === undefined) {
        return 0;
      }
      return resp.total;
    },
    itemPerPage() {
      let resp = this.$store.state.itemList_module.itemListHeader;
      if (resp.per_page === undefined) {
        return 1;
      }
      return resp.per_page;
    },
    currentPage() {
      let resp = this.$store.state.itemList_module.itemListHeader;
      if (resp.current_page === undefined) {
        return 1;
      }
      return resp.current_page;
    },
    headers() {
      return [
        {
          text: this.$t("message.item-code"),
          value: "item_code",
          width: "4%",
        },
        {
          text: this.$t("message.image"),
          value: "image_url",
          align: "center",
          width: "13%",
        },
        { text: this.$t("message.name"), value: "item_name", width: "0%" },
        {
          text: this.$t("message.category"),
          value: "category_name",
          width: "8%",
        },
        {
          text: this.$t("message.marketplace"),
          value: "marketplace",
          align: "center",
          width: "8%",
        },
        {
          text: this.$t("message.initial-stock"),
          value: "initial_stock",
          align: "center",
          width: "8%",
        },
        {
          text: this.$t("message.stock"),
          value: "stock",
          align: "center",
          width: "8%",
        },
        {
          text: this.$t("message.price"),
          value: "price",
          align: "center",
          width: "8%",
        },
        {
          text: this.$t("message.price_with_tax"),
          value: "price_w_tax",
          align: "center",
          width: "8%",
        },
        {
          text: this.$t("message.publish"),
          value: "is_publish",
          align: "center",
          width: "15%",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "15%",
        },
      ];
    },
  },
};
</script>

<style scoped>
.grid-btn-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, auto));
}
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}

.v-data-footer-top {
  border-top: none !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.input-field-class {
  background-color: white;
  color: black;
  width: 100%;
  padding: 2px;
  height: 150%;
  outline: none;
  border: 1px solid grey;
}
</style>
