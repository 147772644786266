<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="85rem"
      content-class="custom-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          style="margin-top: -10px"
          dark
          v-bind="attrs"
          v-on="on"
          rounded
          @click="fetchItemDetail(editData)"
        >
          {{ $t("message.edit-item-title") }}
        </v-btn>
      </template>
      <v-card>
        <v-form @submit.prevent="submitFormEdit">
          <v-row no-gutters>
            <v-col cols="12" md="5">
              <v-card-title>
                <span class="text-h5">{{ $t("message.label-update") }}</span>
              </v-card-title>
            </v-col>

            <v-col cols="12" md="5">
              <div class="grid-btn-submit">
                <div class="">
                  <div class="d-flex align-center">
                    <label class="px-2">{{ this.$t("message.publish") }}</label>
                    <v-switch v-model="editForm.is_active"></v-switch>
                  </div>
                </div>

                <div class="mx-2 my-2">
                  <v-btn
                    @click="dialog = false"
                    block
                    @click.prevent="clearEditItem()"
                    color="secondary"
                  >
                    {{ $t("message.back-button") }}
                  </v-btn>
                </div>

                <div class="mx-2 my-2">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    @submit.prevent="submitFormEdit"
                  >
                    {{ $t("message.submit-button") }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="5">
                  <v-row>
                    <!-- <v-col cols="12" md="3" xs="2">
                        <span>{{ $t("message.shop") }}</span>
                        </v-col>
                        <v-col cols="12" md="9" xs="2">
                        <v-autocomplete
                            :items="shopList"
                            outlined
                            v-model="editForm.store_id"
                            :error-messages="shopErrors"
                            @input="$v.editForm.store_id.$touch()"
                            @blur="$v.editForm.store_id.$touch()"
                            required
                            item-text="store_name"
                            item-value="id"
                        ></v-autocomplete>
                        </v-col>

                        <div class="class-horizontal-line">
                        <span class="horizontal-line"></span>
                        </div> -->

                    <v-col
                      cols="12"
                      md="3"
                      xs="2"
                      style="display: flex; align-items: center"
                    >
                      <span class="">{{ $t("message.images") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-file-input
                        persistent-placeholder
                        height="55px"
                        v-model="editForm.images"
                        accept="image/*"
                        id="file-input"
                        ref="fileImg"
                        :prepend-icon="null"
                        style="cursor: pointer"
                        prepend-inner-icon="mdi-plus-circle-outline"
                        type="file"
                        @change="upload"
                        :disabled="uploadImg.length == 10"
                      ></v-file-input>
                      <p style="font-size: 12px; color: red; margin-left: 28px">
                        {{ $t("message.label-max-img-upload") }}
                      </p>
                    </v-col>

                    <template>
                      <v-container
                        class="d-flex justify-start"
                        style="max-width: 500px;"
                      >
                        <v-row class="mb-6" no-gutters justify="start">
                          <v-col cols="12" md="3"></v-col>
                          <div class="grid-image">
                            <!-- <div v-for="(cardImg, idx) in 10" :key="idx"> -->
                            <v-col v-for="(img, i) in uploadImg" :key="i">
                              <div class="card-image-preview">
                                <v-img
                                  :lazy-src="img.image_url"
                                  :src="img.image_url"
                                  height="68px"
                                  width="82px"
                                  class=""
                                >
                                  <v-icon
                                    class="icon-close"
                                    @click.prevent="deleteImg(img.id, i, img.image_url)"
                                  >
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-img>
                              </div>
                            </v-col>
                          </div>
                          <!-- </div> -->
                        </v-row>
                      </v-container>
                    </template>

                    <v-col cols="12" md="3" xs="2">
                      <span class="label">{{ $t("message.item-code") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-text-field
                        v-model="editForm.item_code"
                        @input="$v.editForm.item_code.$touch()"
                        @blur="$v.editForm.item_code.$touch()"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <span>{{ $t("message.product-name") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-text-field
                        v-model="editForm.item_name"
                        :error-messages="productNameErrors"
                        @input="$v.editForm.item_name.$touch()"
                        @blur="$v.editForm.item_name.$touch()"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <v-col cols="12" md="3" xs="2">
                      <span class="label lable-class">{{
                        $t("message.category")
                      }}</span>
                    </v-col>
                    <!-- <v-col cols="12" md="9" xs="2">
                            <v-autocomplete
                                v-model="editForm.category"
                                :items="itemListCategory"
                                item-text="category_name"
                                item-value="id"
                                outlined
                                required
                                :error-messages="categoryErrors"
                                @input="$v.editForm.category.$touch()"
                                @blur="$v.editForm.category.$touch()"
                                v-on:change="checkingBulk"
                                auto-select-first
                            >
                            <template v-slot:item="{item}">
                                <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                            </template>
                            </v-autocomplete>
                        </v-col> -->

                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="editForm.category_parent"
                        :items="categoryListParent"
                        item-text="category_name"
                        item-value="id"
                        @change="changeCategoryParent"
                        outlined
                        required
                        :error-messages="category_parentErrors"
                        @input="$v.editForm.category_parent.$touch()"
                        @blur="$v.editForm.category_parent.$touch()"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="editForm.category_child"
                        :items="categoryListChild"
                        item-text="category_name"
                        item-value="id"
                        :error-messages="category_childErrors"
                        @input="$v.editForm.category_child.$touch()"
                        @blur="$v.editForm.category_child.$touch()"
                        outlined
                        @change="changeCategoryChild"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <v-autocomplete
                        v-model="editForm.category_grand_child"
                        :items="categoryListGrandChild"
                        item-text="category_name"
                        item-value="id"
                        :error-messages="category_grandchildErrors"
                        @input="$v.editForm.category_grand_child.$touch()"
                        @blur="$v.editForm.category_grand_child.$touch()"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" xs="2">
                      <span>{{ this.$t("message.marketplace") }}</span>
                    </v-col>
                    <v-col cols="12" md="9" xs="2">
                      <v-select
                        v-model="editForm.marketplace"
                        :items="marketplace"
                        item-text="marketplace_name"
                        item-value="id"
                        multiple
                        outlined
                        chips
                        persistent-hint
                      ></v-select>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span>{{
                          $t("message.label-Tax_process_category")
                        }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-select
                          v-model="editForm.tax_process_category"
                          :items="taxProcessCategory"

                          :error-messages="taxCategoryErrors"
                          @input="$v.editForm.tax_process_category.$touch()"
                          @blur="$v.editForm.tax_process_category.$touch()"
                          required
                          outlined
                          item-text="text"
                          item-value="value"
                          @change="changeTaxProcessCategory"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" xs="2">
                        <span>{{ $t("message.label-tax_rate") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-select
                          v-model="editForm.tax_rate"
                          :items="tax_rate_selected"

                          :error-messages="tax_rateErrors"
                          @input="$v.editForm.tax_rate.$touch()"
                          @blur="$v.editForm.tax_rate.$touch()"
                          required
                          outlined
                          item-text="text"
                          item-value="value"
                          @change="changeTax_rate"
                          :disabled="editForm.tax_process_category == 3"
                        ></v-select>
                      </v-col>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12" xs="2">
                        <span class="d-flex align-center">{{
                          $t("message.price")
                        }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          :error-messages="priceErrors"
                          type="number"
                          v-model="editForm.price"
                          outlined

                          @change="changePrice"
                          :disabled="this.priceIsTrue == false"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="2">
                        <span>{{ $t("message.tax") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          v-model="editForm.tax"
                          outlined
                          @change="changeTax"
                          :disabled="editForm.tax_process_category == 3"
                        ></v-text-field>
                      </v-col>
                    </div>
                    <v-col cols="12" md="3" xs="2">
                      <span>{{ this.$t("message.price_with_tax") }}</span>
                    </v-col>
                    <v-col cols="12" md="3" xs="2">
                      <v-text-field
                        v-model="editForm.price_with_tax"
                        outlined
                        @change="changePriceWithTax"
                        :disabled="this.price_w_taxIsTrue == false"
                      ></v-text-field>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>
                  </v-row>
                </v-col>

                <v-col class="d-flex justify-center">
                  <v-divider vertical class="class-vertical-line"></v-divider>
                </v-col>

                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12" md="2" xs="2">
                      <span>{{ $t("message.description") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-textarea
                        hide-details="false"
                        v-model="editForm.description"
                        full-width
                        height="90px"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="2" xs="2">
                      <span style="width: 10rem">{{
                        $t("message.specification")
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-textarea
                        v-model="editForm.specification"
                        hide-details="false"
                        full-width
                        height="90px"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <!-- <v-col cols="12" xs="2">
                      <span>{{ this.$t("message.product_dimension") }}</span>
                    </v-col>
                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span style="float: left; width: 26px">{{
                          $t("message.width")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="editForm.width"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span style="float: left">{{
                          $t("message.length")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="editForm.length"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span style="float: left">{{
                          $t("message.height")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="editForm.height"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="">
                        <span style="float: left">{{
                          $t("message.weight")
                        }}</span>
                        <v-text-field
                          hide-details="false"
                          type="number"
                          v-model="editForm.weight"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </div> -->

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <div class="" style="width: 100px">
                          <label class="py-5">{{
                            $t("message.label-locker")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10" xs="2">
                        <v-switch v-model="editForm.locker"></v-switch>
                      </v-col>

                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <label class="py-5">{{
                            $t("message.contacles_delivery")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10" xs="2">
                        <v-switch
                          v-model="editForm.delivery"
                          required
                          :error-messages="deliveryErrors"
                          @input="$v.editForm.delivery.$touch()"
                          @blur="$v.editForm.delivery.$touch()"
                        ></v-switch>
                      </v-col>
                    </div>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <div class="grid-two-column">
                      <v-col cols="12">
                        <span>{{ this.$t("message.stock") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          v-model="editForm.stock"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <span>{{ $t("message.initial-stock") }}</span>
                      </v-col>
                      <v-col cols="12" xs="2">
                        <v-text-field
                          type="number"
                          v-model="editForm.initial_stock"
                          required
                          outlined
                          :error-messages="initial_stockErrors"
                          @input="$v.editForm.initial_stock.$touch()"
                          @blur="$v.editForm.initial_stock.$touch()"
                        ></v-text-field>
                      </v-col>
                    </div>

                    <div class="grid-two-column">
                      <v-col
                        cols="12"
                        style="display: flex; align-items: center"
                      >
                        <div class="">
                          <label class="py-8">{{
                            $t("message.reset-flag")
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-switch
                          v-model="editForm.flag_reset"
                          label="在庫リセットを有効にすると、在庫数をワンクリックで初期在庫数にできるようになります。"
                        ></v-switch>
                      </v-col>
                    </div>

                    <div>
                      <v-divider></v-divider>
                    </div>

                    <v-col cols="12" md="2" xs="2">
                      <span>{{ $t("message.label-tags") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2">
                      <v-text-field
                        type="text"
                        v-model="editForm.tag"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  minValue,
  decimal,
} from "vuelidate/lib/validators";
export default {
  name: "EditFormItem",
  mixins: [validationMixin],
  props: ["editData"],
  validations: {
    editForm: {
      item_name: { required, minLength: minLength(10) },
      store_id: { required },
      category_parent: { required },
      category_child: { required },
      category_grand_child: { required },
      // category: { required },
      price: { required, numeric },
      maxQty: { required, numeric },
      tax: { required, decimal },
      initial_stock: { required },
      description: { required, minLength: minLength(10) },
      specification: { required, minLength: minLength(10) },
      images: { required },
      is_active: { required },
      item_code: { required },
      weight: { required, numeric },
      flag_reset: { required },
      width: { required, numeric },
      length: { required, numeric },
      height: { required, numeric },
      tax_rate: { required, numeric },
      tax_process_category: { required },
      locker: { required },
      stock: { required },
      marketplace: { required },
      price_with_tax: { required },
      delivery: { required },
    },
  },
  data() {
    return {
      dialog: false,
      status: false,
      data_switch_2: false,
      data_switch_3: false,
      data_switch_4: false,
      priceIsTrue: false,
      price_w_taxIsTrue: false,
      editForm: {
        item_name: "",
        tag: "",
        // category: "",
        category_parent: "",
        category_child: "",
        category_grand_child: "",
        price: "",
        is_bulk: "",
        tax: "",
        initial_stock: "",
        description: "",
        specification: "",
        marketplace: [],
        item_code: "",
        is_active: 0,
        delivery: 0,
        stock: "",
        images: null,
        store_id: "",
        weight: "",
        width: "",
        length: "",
        height: "",
        flag_reset: 0,
        tax_rate: "",
        tax_process_category: "",
        price_with_tax: "",
        locker: "",
        minimum_stock: 100,
        minimum_order: 1,
        // minQty: "",
        maxQty: "",
      },
      categoryListGrandChild: [],
      item_id: null,
      message: {},
    };
  },
  beforeMount() {
    // this.fetchItemDetail();
  },

  methods: {
    async fetchItemDetail(itemId) {
      // console.log(itemId, 'itemId');
      this.item_id = itemId;
      await this.$store.dispatch("itemList_module/fetchItemListDetail", itemId);
      await this.$store.dispatch("marketplace_module/fetchMarketPlace");

      let itemDetail = this.$store.state.itemList_module.itemDetail;
      this.$store.dispatch("uploadImage_module/setImg", itemDetail.images);
      let data_marketplace = [];
      itemDetail.marketplace.forEach((val) => {
        data_marketplace.push(val.id);
      });

      if (itemDetail.category_child_id !== null) {
        await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
          parent_id: itemDetail.category_child_id,
        });
        let result = this.$store.state.itemList_module.categoryGrandChild;
        this.categoryListGrandChild = result
      } else {
        await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
          parent_id: 0,
        });
        let result = this.$store.state.itemList_module.categoryGrandChild;
        this.categoryListGrandChild = result
      }
      if(itemDetail.category_parent_id !== null) {
        await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
          parent_id: itemDetail.category_parent_id,
        });
      }

      // NOTES: CONDITIONAL TAX CATEGORY
      if(itemDetail.tax_category == 1) {
        this.priceIsTrue = true
      } else if(itemDetail.tax_category == 2) {
        this.price_w_taxIsTrue = true
      } else if(itemDetail.tax_category == 3) {
        this.priceIsTrue = true
      }

      // NOTES: CONDITIONAL COUNT PRICE_W_TAX
      // if(itemDetail.tax_category == 1) {
      //   console.log("masuk kesini");
      //   if(itemDetail.tax_rate == 8) {
      //       this.editForm.tax = Math.floor(itemDetail.price * 0.08 * 100) / 100
      //     } else if(itemDetail.tax_rate == 10) {
      //       this.editForm.tax = Math.floor(itemDetail.price * 0.1 * 100) / 100
      //     }
      //   this.editForm.price_with_tax = isNaN(parseFloat(itemDetail.price)+parseFloat(itemDetail.tax)) ? 0 : parseFloat(itemDetail.price)+parseFloat(itemDetail.tax)
      // } else if(itemDetail.tax_category == 2) {
      //   if(itemDetail.tax_rate == 8) {
      //     this.editForm.tax = Math.floor(itemDetail.price_with_tax / (1 + 0.08) * 0.08)
      //   } else if(itemDetail.tax_rate == 10) {
      //     this.editForm.tax = Math.floor(itemDetail.price_with_tax / (1 + 0.1) * 0.1)
      //   }
      //   this.editForm.price = isNaN(parseFloat(itemDetail.price_with_tax) - parseFloat(itemDetail.tax)) ? 0 : parseFloat(itemDetail.price_with_tax) - parseFloat(itemDetail.tax)
      //   this.editForm.price_with_tax = parseFloat(itemDetail.price) + parseFloat(itemDetail.tax)
      // } else if(itemDetail.tax_category == 3) {
      //   this.editForm.price_with_tax = parseFloat(itemDetail.price) + parseFloat(itemDetail.tax)

      // }
      this.editForm.tax = itemDetail.tax;
      this.editForm.price = itemDetail.price;
      this.editForm.price_with_tax = parseFloat(itemDetail.price) + parseFloat(itemDetail.tax);

      this.editForm.item_name = itemDetail.item_name;
      this.editForm.tag = itemDetail.tag;
      // this.editForm.category = itemDetail.category_id;
      this.editForm.category_parent = itemDetail.category_parent_id;
      this.editForm.category_child = itemDetail.category_child_id;
      this.editForm.category_grand_child = itemDetail.category_grandchild_id;
      this.editForm.price = itemDetail.price;
      this.editForm.is_bulk = itemDetail.is_bulk;
      this.editForm.tax = itemDetail.tax;
      this.editForm.initial_stock = parseInt(itemDetail.stock_beginning);
      this.editForm.description = itemDetail.description;
      this.editForm.specification = itemDetail.specification;
      this.editForm.item_code = itemDetail.item_code;
      this.editForm.is_active = itemDetail.is_active;
      this.editForm.delivery = itemDetail.item_shipping_type;
      this.editForm.stock = itemDetail.stock_total;
      this.editForm.marketplace = data_marketplace;
      this.editForm.images = itemDetail.images;
      this.editForm.store_id = itemDetail.store_id;
      this.editForm.weight = parseInt(itemDetail.weight);
      this.editForm.width = parseInt(itemDetail.width);
      this.editForm.length = parseInt(itemDetail.length);
      this.editForm.height = itemDetail.height;
      this.editForm.flag_reset = itemDetail.flag_reset;
      this.editForm.tax_rate = itemDetail.tax_rate;
      this.editForm.tax_process_category = itemDetail.tax_category;
      // this.editForm.price_with_tax = isNaN(parseFloat(itemDetail.price) + parseFloat(itemDetail.tax)) ? 0 : parseFloat(itemDetail.price) + parseFloat(itemDetail.tax)
      this.editForm.locker = itemDetail.is_locker;
      this.editForm.minimum_stock = parseInt(itemDetail.minimum_stock);
      this.editForm.minimum_order =
        parseInt(itemDetail.minimum_order) == 0
          ? 1
          : parseInt(itemDetail.minimum_order);
      // this.editForm.minQty
      this.editForm.maxQty = itemDetail.max_qty;
    },
    async upload(event) {
      if (this.editForm.images != null) {
        await this.$store.dispatch("uploadImage_module/uploadImg", {
          images: this.editForm.images,
          path: "itemsV2",
        });
        // let uploadImg = await this.$store.state.uploadImage_module.resLink;
        // console.log(uploadImg.length, 'images')
      }
    },

    async deleteImg(imageId, i, imageUrl) {
      //   if (this.isEdited > -1) {
      await this.$store.dispatch("uploadImage_module/deleteImgItem", {
        imgId: imageId,
        item_id: this.item_id,
        imgIndex: i,
      });
      await this.$store.dispatch("uploadImage_module/deleteImgBucket", {
        path: imageUrl,
      });
    },
    fetchShopList() {
      return this.$store.dispatch("shop_module/fetchShopAll");
    },
    // HIDE
    // fetchItemListCategory() {
    //   return this.$store.dispatch("itemList_module/fetchItemListCategory");
    // },
    async changeCategoryParent() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id:
          this.editForm.category_parent === null
            ? ""
            : this.editForm.category_parent,
      });
    },
    async changeCategoryChild() {
      await this.$store.dispatch("itemList_module/fetchCategoryListNew", {
        parent_id:
          this.editForm.category_child === null
            ? ""
            : this.editForm.category_child,
      });
      let result = this.$store.state.itemList_module.categoryGrandChild;
        this.categoryListGrandChild = result
    },
    changeTaxProcessCategory() {
      if(this.editForm.tax_process_category == 1) {
        this.priceIsTrue = true
        // this.tax_rate_isTrue = false
        this.price_w_taxIsTrue = false
        this.editForm.tax = ""
        this.editForm.price = ""
        this.editForm.price_with_tax = ""
      } else if(this.editForm.tax_process_category == 2) {
        this.price_w_taxIsTrue = true
        // this.tax_rate_isTrue = false
        this.priceIsTrue = false
        this.editForm.tax = ""
        this.editForm.price = ""
        this.editForm.price_with_tax = ""
      } else if(this.editForm.tax_process_category == 3) {
        this.priceIsTrue = true
        this.price_w_taxIsTrue = false
        this.editForm.tax_rate = 0
        this.editForm.tax = 0
        this.editForm.price = ""
        this.editForm.price_with_tax = ""
      }
    },
    changeTax_rate() {
      this.calcTax();
      this.calcPrice();
      this.calcPriceWithTax();
    },
    changePrice() {
      this.calcTax();
      this.calcPriceWithTax();
    },
    changeTax() {
      this.calcPrice();
      this.calcPriceWithTax();
    },
    changePriceWithTax() {
      this.calcTax();
      this.calcPrice();
    },
    calcTax() {
      if (this.editForm.tax_process_category == 1) {
        if (this.editForm.tax_rate == 8) {
          this.editForm.tax = Math.floor(this.editForm.price * 8) / 100;
        } else if (this.editForm.tax_rate == 10) {
          this.editForm.tax = Math.floor(this.editForm.price * 10) / 100;
        }
      } else if (this.editForm.tax_process_category == 2) {
        if (this.editForm.tax_rate == 8) {
          this.editForm.tax = Math.ceil( Math.floor(
            (((this.editForm.price_with_tax * 100) / (100 + 8)) * 8)
          ) / 100);
        } else if (this.editForm.tax_rate == 10) {
          this.editForm.tax = Math.ceil( Math.floor(
            (((this.editForm.price_with_tax * 100) / (100 + 10)) * 10)
          ) / 100);
        }
      } else if (this.editForm.tax_process_category == 3) {
        this.editForm.tax = 0;
      }
    },
    calcPrice() {
      if (this.editForm.tax_process_category == 2) {
        this.editForm.price = isNaN(
          parseFloat(this.editForm.price_with_tax) - parseFloat(this.editForm.tax)
        )
          ? 0
          : parseFloat(this.editForm.price_with_tax) - parseFloat(this.editForm.tax);
      }
    },
    calcPriceWithTax() {
      if (this.editForm.tax_process_category == 1) {
        this.editForm.price_with_tax = isNaN(
          parseFloat(this.editForm.price) + parseFloat(this.editForm.tax)
        )
          ? 0
          : parseFloat(this.editForm.price) + parseFloat(this.editForm.tax);
      } else if (this.editForm.tax_process_category == 3) {
        this.editForm.price_with_tax = this.editForm.price;
      }
    },
    checkingBulk(val) {
      this.itemListCategory.forEach((el) => {
        if (el.id === val) {
          this.editForm.is_bulk = el.is_bulk;
        }
      });
      return this.editForm.is_bulk;
    },
    clearEditItem() {
      this.$store.dispatch("uploadImage_module/clearImg");
      this.$v.$reset();
    },
    submitFormEdit() {
      this.$v.$touch();

      if (this.editForm.is_active == true) {
        this.editForm.is_active = 1;
      } else {
        this.editForm.is_active = 0;
      }

      if (this.editForm.locker == true) {
        this.editForm.locker = 1;
      } else {
        this.editForm.locker = 0;
      }

      if (this.editForm.delivery == true) {
        this.editForm.delivery = 1;
      } else {
        this.editForm.delivery = 0;
      }

      if (this.editForm.flag_reset == true) {
        this.editForm.flag_reset = 1;
      } else {
        this.editForm.flag_reset = 0;
      }
      let payload = {
        item_name: this.editForm.item_name,
        // category_id: this.editForm.category,
        category_parent_id: this.editForm.category_parent,
        category_child_id: this.editForm.category_child,
        category_grandchild_id: this.editForm.category_grand_child,
        price: this.editForm.price,
        tax: this.editForm.tax,
        initial_stock: parseInt(this.editForm.initial_stock),
        description: this.editForm.description,
        specification: this.editForm.specification,
        store_id: this.editForm.store_id,
        item_code: this.editForm.item_code,
        images: this.uploadImg,
        flag_reset:
          this.editForm.flag_reset == undefined || null
            ? ""
            : this.editForm.flag_reset,
        weight: this.editForm.weight,
        width: this.editForm.width,
        length: this.editForm.length,
        height: this.editForm.height,
        marketplace: this.editForm.marketplace,
        // price_with_tax: this.editForm.price_with_tax,
        item_shipping_type: this.editForm.delivery,
        stock: this.editForm.stock,
        tag: this.editForm.tag,
        minimum_stock: this.editForm.minimum_stock,
        minimum_order: this.editForm.minimum_order,
        is_active: this.editForm.is_active,
        tax_rate: this.editForm.tax_rate,
        tax_category: this.editForm.tax_process_category,
        is_locker: this.editForm.locker,
        max_qty: this.editForm.maxQty,
      };
      this.message.title = this.$t("message.title-updateItem-success-message");
      // console.log(payload, 'payload');
      this.$store.dispatch("itemList_module/updateListItem", {
        id: this.item_id,
        data: payload,
        message: {
          title: this.message.title,
        },
      });
    },
  },
  created() {
  },
  mounted() {
    this.$store.dispatch("uploadImage_module/clearImg");
  },
  computed: {
    stateStoreId() {
      return this.$store.state.itemList_module.storeIdform;
    },
    marketplace() {
      return this.$store.state.marketplace_module.marketplace_list;
    },
    uploadImg() {
      return this.$store.state.uploadImage_module.resLink;
    },
    taxProcessCategory() {
      return [
        { id: 1, key: 1, text: "外税", value: 1 },
        { id: 2, key: 2, text: "内税", value: 2 },
        { id: 3, key: 3, text: "非課税", value: 3 },
      ];
    },
    tax_rate_selected() {
      if(this.editForm.tax_process_category != 3) {
        return [
          { id: 1, key: 1 ,text: "8%", value: 8 },
          { id: 2, key: 2 ,text: "10%", value: 10 },
        ]
      } else {
        return [
          { id: 0, key: 0, text: "-", value: 0 },
          { id: 1, key: 1, text: "8%", value: 8 },
          { id: 2, key: 2, text: "10%", value: 10 },
        ]
      }
    },
    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    categoryListParent() {
      return this.$store.state.itemList_module.categoryParent;
    },
    categoryListChild() {
      return this.$store.state.itemList_module.categoryChild;
    },
    // categoryListGrandChild() {
    //   console.log(this.$store.state.itemList_module.categoryGrandChild, 'state grandChild');
    //   return this.$store.state.itemList_module.categoryGrandChild;
    // },
    // HIDE
    // itemListCategory() {
    //   return this.$store.state.itemList_module.itemListCategory;
    // },

    // err message
    shopErrors() {
      const errors = [];
      if (!this.$v.editForm.store_id.$dirty) return errors;
      !this.$v.editForm.store_id.required &&
        errors.push(this.$t("message.err-msg-shop"));
      return errors;
    },
    item_codeErrors() {
      const errors = [];
      if (!this.$v.editForm.item_code.$dirty) return errors;
      !this.$v.editForm.item_code.required &&
        errors.push(this.$t("message.err-msg-item-code"));
      return errors;
    },
    deliveryErrors() {
      const errors = [];
      if (!this.$v.editForm.delivery.$dirty) return errors;
      !this.$v.editForm.delivery.required &&
        errors.push("The switch shipping type is required!");
      return errors;
    },
    tax_rateErrors() {
      const errors = [];
      if (!this.$v.editForm.tax_rate.$dirty) return errors;
      !this.$v.editForm.tax_rate.numeric &&
        errors.push(this.$t("message.err-msg-tax_rate-numeric"));
      !this.$v.editForm.tax_rate.required &&
        errors.push(this.$t("message.err-msg-tax_rate-required"));
      if (this.editForm.tax_rate < 0) {
        errors.push(this.$t("message.err-msg-tax_rate-not-zero"));
      }
      // if (this.editForm.tax_rate > 10) {
      //   errors.push(this.$t("フィールド税率が10を超える"));
      // }
      return errors;
    },
    productNameErrors() {
      const errors = [];
      if (!this.$v.editForm.item_name.$dirty) return errors;
      !this.$v.editForm.item_name.required &&
        errors.push(this.$t("message.err-msg-item-name"));
      return errors;
    },
    // category_parentErrors() {
    //   const errors = [];
    //   if (!this.$v.editForm.category_parent.$dirty) return errors;
    //   !this.$v.editForm.category_parent.required &&
    //     errors.push(this.$t("message.err-msg-category"));
    //   return errors;
    // },
    category_parentErrors() {
      const errors = [];
      if (!this.$v.editForm.category_parent.$dirty) return errors;
      if(!this.$v.editForm.category_parent.required && !this.$v.editForm.category_child.required && !this.$v.editForm.category_grand_child.required ) {
        errors.push(this.$t("message.label-category_parent"));
      }
      return errors;
    },
    category_childErrors() {
      const errors = [];
      if (!this.$v.editForm.category_child.$dirty) return errors;
      if(!this.$v.editForm.category_child.required && !this.$v.editForm.category_parent.required && !this.$v.editForm.category_grand_child.required) {
        errors.push(this.$t("message.label-category_child"));
      }
      return errors;
    },
    category_grandchildErrors() {
      const errors = [];
      if (!this.$v.editForm.category_grand_child.$dirty) return errors;
      if(!this.$v.editForm.category_grand_child.required && !this.$v.editForm.category_parent.required && !this.$v.editForm.category_child.required) {
        errors.push(this.$t("message.label-category_grand_child"));
      }
      return errors;
    },
    taxCategoryErrors() {
      const errors = [];
      if (!this.$v.editForm.tax_process_category.$dirty) return errors;
      !this.$v.editForm.tax_process_category.required &&
        errors.push(this.$t("message.err-msg-taxCategory"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.editForm.price.$dirty) return errors;
      !this.$v.editForm.price.numeric &&
        errors.push(this.$t("message.err-msg-price-num"));
      !this.$v.editForm.price.required &&
        errors.push(this.$t("message.err-msg-price"));
      return errors;
    },
    taxErrors() {
      const errors = [];
      if (!this.$v.editForm.tax.$dirty) return errors;
      !this.$v.editForm.tax.decimal &&
        errors.push(this.$t("message.err-msg-tax-num"));
      !this.$v.editForm.tax.required &&
        errors.push(this.$t("message.err-msg-tax"));
      return errors;
    },
    initial_stockErrors() {
      const errors = [];
      if (!this.$v.editForm.initial_stock.$dirty) return errors;
      !this.$v.editForm.initial_stock.required &&
        errors.push(this.$t("message.err-msg-stock"));
      return errors;
    },
  },
};
</script>

<style>
.grid-btn-submit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, auto));
}
.grid-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, auto));
  column-gap: 1rem;
}
.grid-image {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, auto));
  column-gap: 1.5rem;
  row-gap: 0.8rem;
  /* max-width: 1000px; */
  /* column-gap: 1rem; */
  margin-bottom: 5px;
}
/* .icon-close {
  position: absolute;
  z-index: -1;
} */
.card-image-preview {
  width: 82px;
  height: 70px;
  border: 1px solid black;
  border-radius: 5px;
}
/* .image-class {
  width: 50%;
  height: 10vh;
} */
/* .custom-dialog.v-dialog{
    margin-left: 15rem;
} */
.grid-form {
  margin-left: 20px;
}
/* .vertical-line {
  border-left: 1px solid black;
  height: 155vh;
  position: absolute;
  left: 50%;
  top: 10%;
}
.horizontal-line {
  border-bottom: 1px solid black;
  width: 40vw;
  position: absolute;
  margin: auto;
}
.horizontal-line-textarea {
  border-bottom: 1px solid black;
  width: 40vw;
  position: absolute;
  margin: auto;
  left: 52%;
} */

.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
@media only screen and (max-width: 767px) {
  .class-vertical-line {
    display: none !important;
  }
}
/* .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 2px;
} */
</style>
